import React from 'react';
import {Helmet} from 'react-helmet';
import _ from 'lodash';

import {safePrefix} from '../utils';
import Header from './Header';
import Footer from './Footer';
import Favicon from '../../static/images/favicon.ico'
import Icon32x from '../../static/images/logo32.png'
import Icon96x from '../../static/images/logo96.png'
import IconApple from '../../static/images/apple-touch-icon.png'
import IconMs from '../../static/images/mstile-144x144.png'

export default class Body extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{_.get(this.props, 'pageContext.frontmatter.title') && _.get(this.props, 'pageContext.frontmatter.title') + ' - '}{_.get(this.props, 'pageContext.site.siteMetadata.title')}</title>
                    <meta charSet="utf-8" />
                    <link rel="stylesheet" href={safePrefix('assets/css/main.css')} />
                    <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
                    <link rel="shortcut icon" href={Favicon} />
                    <link rel="icon" type="image/png" href={Icon32x} />
                    <link rel="icon" type="image/png" href={Icon96x} />
                    <link rel="apple-touch-icon" sizes="180x180" href={IconApple} />
                    <meta name="msapplication-TileColor" content="#ffffff" />
                    <meta name="msapplication-TileImage" content={IconMs} />
                    <meta name="Description" content="MFC Hohenau an der March - Modellflug seit 1981."/>
                    <noscript>{`<link rel="stylesheet" href=${safePrefix('assets/css/noscript.css')} />`}</noscript>
                    <body className={((_.get(this.props, 'pageContext.frontmatter.template') === _.get(this.props, 'pageContext.site.siteMetadata.landing_template')) ? 'landing ' : '')} />
                </Helmet>
                    <div id="page-wrapper">
                        <Header {...this.props} />
                        {this.props.children}
                        <Footer {...this.props} />
                    </div>
            </React.Fragment>
        );
    }
}
